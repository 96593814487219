import { Injectable } from '@angular/core';
import { ITask } from '@c/trading-hub/trading-hub.model';
import { ITradingPlan } from '@c/trading-hub/trading-plan/trading-plan.model';
import { WeekdayTradesTabs } from '@c/trading-log/dashboard/tables/weekday-trades-table/weekday-trades-table.model';
import { TradingLogDashboardTabs } from '@c/trading-log/dashboard/trading-log-dashboard-container/trading-log-dashboard-container.model';
import { TradingLogSymbolSummaryPanelStateType } from '@c/trading-log/shared';
import { TradingLogTradesInfoColumnType, TradingLogTradesSortingStateModel } from '@c/trading-log/trades';
import {
  TradingLogTradesFilterFormModel,
  defaultTLTradesFilterValue,
} from '@c/trading-log/trades/trading-log-trades-container/trading-log-trades-filter-form/models';
import { TradingLogContainerTab } from '@c/trading-log/trading-log-container/trading-log-container.model';
import {
  ChartTabs,
  Countries,
  DefaultOptionsSymbolsTableSortState,
  DefaultPrintOptions,
  DefaultRockyAlwaysVisible,
  DefaultStocksSymbolsTableSortState,
  JobStatus,
  LowestCloseLineIndicatorOptions,
  LowestHighestCloseLineIndicatorOptions,
  PowerXStrategyIndicatorOptions,
  STOCK_SCREENER_DETAILS_PANEL_DEFAULT_WIDTH_PERCENT,
  ShowEntryAndExitOptions,
  ShowTradeOptions,
  TLDashboardMetricDetailsModalPageSizeOptions,
  TabNames,
  TechnicalIndicators,
  Themes,
  TradePositions,
  TradingHubTabs,
  TradingLogInputDateFormats,
  UserAccessLevels,
} from '@const';
import { IMarketData } from '@core/types';
import { QuickLink } from '@m/common/links-organiser/links-organiser.model';
import { ApplicationVersionModel } from '@mod/admin/application-version.model';
import { TradingLogFeelingsDataModel } from '@mod/trading-log/feelings';
import { TradingOrderInput } from '@mod/trading-panel/trading-panel-order.model';
import {
  OrderFilterStatus,
  TradingPanelClientEvent,
  TradingTableColumnSorting,
} from '@mod/trading-panel/trading-panel.model';
import { TradingLogGroupType } from '@t/trading-log/trading-log-group-type.enum';
import { DataWindowUpdateSource, IWheelROIRange, WheelViewModes } from '@t/wheel/wheel.types';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { Sort } from '@angular/material/sort';
import {
  DEFAULT_DIVIDENDS_FILTERS_STATE,
  DEFAULT_IS_DIVIDENDS_FILTERS_HIDDEN,
} from '@c/dividends-content/dividends-scanner-filter/dividends-scanner-filter.data';
import { IDividendsScannerFilter } from '@c/dividends-content/dividends-scanner-filter/dividends-scanner-filter.model';
import { entryIndicatorsOptions } from '@c/dividends-strategy-content/dividends-strategy-chart/dividends-strategy-chart.data';
import { DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE } from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.data';
import { DividendsStrategyScannerFilterModel } from '@c/dividends-strategy-content/dividends-strategy-scanner-filter/dividends-strategy-scanner-filter.model';
import { importTradesListSortDefault } from '@c/import-trades/import-trades-list-table/models/import-trades-list-sort.default';
import {
  TradesListFilterFormModel,
  tradesListFilterFormDefault,
  tradesListFilterFormNoImportedTradesDefault,
} from '@c/import-trades/trades-list-filter-form/models';
import { LoginNavigationSettings } from '@c/my-settings-modal/my-settings-modal.model';
import {
  DateRangeFilterModel,
  getDefaultDateRangeFilterValue,
} from '@c/shared/date-contols/components/filters/daterange-filter/daterange-filter.model';
import { DEFAULT_SCANNER_TABLE_SORT } from '@c/shared/scanner-symbols-list/scanner-symbols-list.data';
import { SortState } from '@c/shared/scanner-symbols-list/scanner-symbols-list.model';
import {
  DEFAULT_IS_SHORT_SELLING_STOCKS_FILTERS_HIDDEN,
  DEFAULT_SHORT_SELLING_STOCKS_FILTERS_STATE,
} from '@c/short-selling-stocks-content/short-selling-stocks-filter/short-selling-stocks-filter.data';
import { IShortSellingStocksScannerFilter } from '@c/short-selling-stocks-content/short-selling-stocks-filter/short-selling-stocks-filter.model';
import { IShortSellingStocksScannerItem } from '@c/short-selling-stocks-content/short-selling-stocks-watchlist-panel/short-selling-stocks-watchlist-panel.model';
import {
  DEFAULT_IS_SHORTING_STOCKS_SCANNER_FILTERS_HIDDEN,
  INITIAL_SHORTING_STOCKS_SCANNER_FILTERS_STATE,
} from '@c/shorting-stocks-scanner-content/shorting-stocks-scanner-filter/shorting-stocks-scanner-filter.data';
import { ShortingStocksScannerFilterModel } from '@c/shorting-stocks-scanner-content/shorting-stocks-scanner-filter/shorting-stocks-scanner-filter.model';
import { DEFAULT_STOCK_SCREENER_TABLE_SIZES } from '@c/stock-screener-content/stock-screener-content.data';
import { StockScreenerTableSizes } from '@c/stock-screener-content/stock-screener-content.model';
import { DEFAULT_STOCK_SCREENER_DETAILS_TAB_INDEX } from '@c/stock-screener-content/stock-screener-details-panel/stock-screener-details-panel.data';
import { DEFAULT_STOCK_SCREENER_FILTERS_STATE } from '@c/stock-screener-content/stock-screener-filter/stock-screener-filter.data';
import { StockScreenerFilterModel } from '@c/stock-screener-content/stock-screener-filter/stock-screener-filter.model';
import { EntryStopIndicatorsOptions } from '@c/wtf-content/wtf-chart/wtf-chart.model';
import {
  DEFAULT_IS_FILTERS_HIDDEN,
  DEFAULT_WTF_FILTERS_STATE,
} from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.data';
import { IWtfScannerFilter } from '@c/wtf-content/wtf-scanner-filter/wtf-scanner-filter.model';
import { DEFAULT_WTF_SCANNER_TAB_INDEX } from '@c/wtf-content/wtf-scanner-watchlist-panel/wtf-scanner-watchlist-panel.data';
import { IMySettings } from './user-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ObservableService {
  constructor() {}

  public isDeveloperAccount = new BehaviorSubject<boolean>(false);
  public applicationVersion = new BehaviorSubject<ApplicationVersionModel>(null);
  public activeTab = new BehaviorSubject<string>(TabNames.PowerX);
  public tradePosition = new BehaviorSubject<TradePositions>(TradePositions.LongAndShort);
  public chartTab = new BehaviorSubject<string>(ChartTabs.Chart);
  public runScannerTradingStrategyId = new BehaviorSubject<number>(null);
  public showEntryAndExitOption = new BehaviorSubject<string>(ShowEntryAndExitOptions.None);
  public showTradeOption = new BehaviorSubject<string>(ShowTradeOptions.None);
  public showTechnicalIndicators = new BehaviorSubject<string>(TechnicalIndicators.Show);
  public symbol = new BehaviorSubject<number>(null);
  public accountSize = new BehaviorSubject<number>(10000);
  public accountRiskPercent = new BehaviorSubject<number>(2.0);
  public accountRiskAmount = new BehaviorSubject<number>(200);
  public joinCommunityBtnVisible = new BehaviorSubject<number>(0);
  public watchPxoTutorialsBtnVisible = new BehaviorSubject<number>(0);
  public firstTradingStrategyId = new BehaviorSubject<number>(null);
  public secondTradingStrategyId = new BehaviorSubject<number>(null);
  public tradeReportTradingStrategyId = new BehaviorSubject<number>(null);
  public selectScannerTab = new ReplaySubject<number>(1);
  public watchlistSort = new ReplaySubject<{ active: string; direction: 'asc' | 'desc' } | null>(1);
  public scannerResultSort = new BehaviorSubject<{ active: string; direction: 'asc' | 'desc' } | null>(null);
  public quickLinks = new BehaviorSubject<QuickLink[]>([]);
  public tlTradesOpened = new BehaviorSubject(null);
  public tlTradesDashboardOpened = new BehaviorSubject(null);
  public tlTradesActiveOpened = new BehaviorSubject(null);
  public tlTradesArchivedOpened = new BehaviorSubject(null);
  public tlTradesDashboardOpenPositionsOpened = new BehaviorSubject(null);
  public tlTradesDashboardSummaryOpened = new BehaviorSubject(null);
  public tlTradesDashboardOptionsOpened = new BehaviorSubject(null);
  public tlTradesDashboardStocksOpened = new BehaviorSubject(null);
  public loginNavigationSettings = new BehaviorSubject<LoginNavigationSettings>({} as LoginNavigationSettings);

  public showVolumeIndicatorOnStartForPowerX = new BehaviorSubject<boolean>(false);
  public showVolumeIndicatorOnStartForWheel = new BehaviorSubject<boolean>(false);
  public showVolumeIndicatorOnStartForBCS = new BehaviorSubject<boolean>(false);
  public showVolumeIndicatorOnStartForDividendsStrategy = new BehaviorSubject<boolean>(false);

  public wheelSymbol = new BehaviorSubject<number | null>(null);
  public isWheelCalculator = new BehaviorSubject<boolean>(false);
  public isWheelPremiums = new BehaviorSubject<boolean>(true);
  public wheelTopTab = new ReplaySubject<number>(1);
  public wheelScannerTab = new ReplaySubject<number>(1);
  public isWheelFiltersHidden = new ReplaySubject<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public wheelFiltersState = new ReplaySubject<Record<string, any>>(1);
  public wheelScannerSortAggressive = new ReplaySubject<{ column: string; direction: 'asc' | 'desc' } | null>(1);
  public wheelScannerSortConservative = new ReplaySubject<{ column: string; direction: 'asc' | 'desc' } | null>(1);
  public wheelScannerSortAll = new ReplaySubject<{ column: string; direction: 'asc' | 'desc' } | null>(1);
  public wheelFiltersWidth = new BehaviorSubject<number>(null);
  public wheelFiltersHeight = new BehaviorSubject<number>(null);
  public wheelWatchlistSort = new ReplaySubject<{ column: string; direction: 'asc' | 'desc' } | null>(1);
  public wheelDataWindowUpdateSource = new ReplaySubject<DataWindowUpdateSource | null>(1);
  public wheelViewMode = new ReplaySubject<WheelViewModes>(1);
  public wheelChartSectionHeight = new ReplaySubject<number>(1);
  public lowestHighestCloseLineIndicatorWheel = new BehaviorSubject<string>(null);
  public lowestCloseLineIndicatorWheel = new BehaviorSubject<string>(null);
  public lowestHighestCloseLineIndicatorBullCallSpread = new BehaviorSubject<string>(null);
  public lowestCloseLineIndicatorBullCallSpread = new BehaviorSubject<string>(null);
  public lowestCloseLineIndicatorStockScreener = new BehaviorSubject<string>(null);
  public showExpectedMoveOneOnWheel = new BehaviorSubject<number>(1);
  public showExpectedMoveTwoOnWheel = new BehaviorSubject<number>(1);
  public valuesInPercentExpectedMove = new BehaviorSubject<number>(1);
  public showPowerXStrategyIndicatorWheel = new BehaviorSubject<boolean>(true);
  public showPowerXStrategyIndicatorBullCallSpread = new BehaviorSubject<boolean>(true);

  public onUserLogout = new Subject<void>();

  // Bull Call Spread
  public bullCallSpreadSymbol = new BehaviorSubject<number>(null);
  public bullCallSpreadTab = new ReplaySubject<number>(1);
  public bullCallSpreadIsFiltersRolledUp = new BehaviorSubject<boolean>(false);
  public bullCallSpreadSelectedDropPercentOptions = new BehaviorSubject<number[]>([]);
  public bullCallSpreadSymbols = new ReplaySubject<number[]>(1);
  public bullCallSpreadDetailsSectionWidth = new ReplaySubject<number | null>(1);

  public holidaysCountry = new BehaviorSubject<Countries | 'both'>(Countries.USA);

  public earningsCalendarSymbol = new BehaviorSubject<number | null>(null);
  public earningsFilterValue = new BehaviorSubject<number | null>(null);
  public earningsCalendarTab = new BehaviorSubject<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public scannerResultUpdated = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public watchlistUpdated = new Subject<any>();
  public theme = new BehaviorSubject<Themes>(Themes.Light);
  public mySettings = new BehaviorSubject<IMySettings>({} as IMySettings);
  public wheelRoiRange = new BehaviorSubject<IWheelROIRange>({} as IWheelROIRange);
  public showMaintenance = new BehaviorSubject<boolean>(false);
  public numberCrunchingStatus = new BehaviorSubject<JobStatus>(JobStatus.Done);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public numberCrunchingCountDownTimerFinished = new Subject<any>();
  public startNumberCrunchingTime = new BehaviorSubject<number>(null);
  public finishNumberCrunchingTime = new BehaviorSubject<number>(null);

  public printOptions$ = new BehaviorSubject<Array<string>>(DefaultPrintOptions);
  public isNeedPrint$ = new BehaviorSubject<boolean>(false);
  public isTradingChartInitialize$ = new BehaviorSubject<boolean>(false);

  public isLoading = new Subject<boolean>();
  public isWheelScannerLoadingFirstTime$ = new Subject<boolean>();
  public isWheelScannerLoading = new Subject<boolean>();
  public powerXInitialized = new BehaviorSubject<number>(0);

  // Trading Log
  public tradingLogContainerTab = new BehaviorSubject<TradingLogContainerTab>(TradingLogContainerTab.Trades);
  public tradingLogTradesTab = new BehaviorSubject<TradingLogGroupType>(TradingLogGroupType.Active);
  public tradingLogTradesFilterValue = new BehaviorSubject<TradingLogTradesFilterFormModel>(defaultTLTradesFilterValue);
  public tradingLogTradesSortingState = new BehaviorSubject<TradingLogTradesSortingStateModel>(null);
  public tradingLogTradesInfoColumnsStateValue = new BehaviorSubject<ReadonlyArray<TradingLogTradesInfoColumnType>>([]);
  public tradingLogTradesInfoColumnsShowDevFieldsValue = new BehaviorSubject<boolean>(false);
  public tradingLogTradesShowTimeColumn = new BehaviorSubject<boolean>(true);

  public tradingLogFirstTimeGroupAdded = new BehaviorSubject<boolean>(false);
  public tradingLogSelectedInputDateFormat = new BehaviorSubject<TradingLogInputDateFormats>(
    TradingLogInputDateFormats.MonthDayYear,
  );
  public tradingLogDashboardSymbolFilterValue = new BehaviorSubject<string>(null);
  public tradingLogDashboardDatesFilterValue = new BehaviorSubject<DateRangeFilterModel>(
    getDefaultDateRangeFilterValue(),
  );
  public tradingLogDashboardAccountsFilterValues = new BehaviorSubject<string>(null);
  public tradingLogDashboardStrategiesFilterValues = new BehaviorSubject<string>(null);
  public tradingLogDashboardOpenPositionsExpandedValue = new BehaviorSubject<boolean>(false);
  public tradingLogDashboardTab = new BehaviorSubject<TradingLogDashboardTabs>(TradingLogDashboardTabs.Summary);
  public tradingLogDashboardWeekdayTradesTab = new BehaviorSubject<WeekdayTradesTabs>(WeekdayTradesTabs.Graph);
  public tradingLogDashboardOptionsSymbolsTableSortState = new BehaviorSubject(DefaultOptionsSymbolsTableSortState);
  public tradingLogDashboardStocksSymbolsTableSortState = new BehaviorSubject(DefaultStocksSymbolsTableSortState);
  public tradingLogDashboardMetricDetailsModalPageSize = new BehaviorSubject(
    TLDashboardMetricDetailsModalPageSizeOptions[0],
  );
  public tradingLogSymbolSummaryPanelWheelState = new BehaviorSubject<TradingLogSymbolSummaryPanelStateType>({});
  public tradingLogSymbolSummaryPanelWtfState = new BehaviorSubject<TradingLogSymbolSummaryPanelStateType>({});
  public tradingLogSymbolSummaryPanelDividendsState = new BehaviorSubject<TradingLogSymbolSummaryPanelStateType>({});

  public tradingLogFeelingsData = new BehaviorSubject<TradingLogFeelingsDataModel>(null);

  // Import trades page
  public importPageTradesListFilterValue = new BehaviorSubject<TradesListFilterFormModel>(
    tradesListFilterFormNoImportedTradesDefault,
  );
  public importPageTradesListSortValue = new BehaviorSubject<Sort>(importTradesListSortDefault);

  // Trading Hub
  public tradingHubTab = new ReplaySubject<TradingHubTabs>(1);
  public tradingHubLastOpenDate = new ReplaySubject<number | null>(1);
  public preTradingChecklistTasks = new ReplaySubject<ITask[] | null>(1);
  public preTradingChecklistOpenOnAppStart = new ReplaySubject<boolean>(1);
  public tradingHubPlans = new ReplaySubject<ITradingPlan[] | null>(1);
  public tradingHubPlansTab = new ReplaySubject<number | null>(1);
  public tradingHubPlanToPrint = new ReplaySubject<ITradingPlan>(1);
  public tradingHubQuotesExpandedState = new ReplaySubject<boolean>(1);
  public tradingHubQuotesLastExpandedDate = new ReplaySubject<number | null>(1);

  // Expected move
  public expectedMoveHasData = new BehaviorSubject<boolean>(false);
  public expectedMoveLiveData = new BehaviorSubject<IMarketData>(null);

  // Trading panel
  public tradierSelectedAccount = new BehaviorSubject<string>(null);
  public tradingPanelSelectedTab = new BehaviorSubject<number>(0);
  public tradingPanelOrdersTabStatusFilter = new BehaviorSubject<OrderFilterStatus>(OrderFilterStatus.All);
  public tradingPanelOrdersHistoryTabStatusFilter = new BehaviorSubject<OrderFilterStatus>(OrderFilterStatus.All);
  public tradingPanelPositionsTabSorting = new BehaviorSubject<TradingTableColumnSorting>(null);
  public tradingPanelOrdersTabSorting = new BehaviorSubject<TradingTableColumnSorting>(null);
  public tradingPanelOrdersHistoryTabSorting = new BehaviorSubject<TradingTableColumnSorting>(null);
  public tradingPanelOrderModified = new BehaviorSubject<number>(null);
  public tradingPanelOrderCanceled = new BehaviorSubject<number>(null);
  public tradingPanelClientEvent = new Subject<TradingPanelClientEvent>();
  public tradingPanelOrderModalPinned = new BehaviorSubject<boolean>(false);
  public tradingPanelOrderInput = new BehaviorSubject<TradingOrderInput>(null);
  public tradingPanelOrderInputState = new BehaviorSubject<TradingOrderInput>(null);
  public tradingPanelHasPrices = new BehaviorSubject<boolean>(true);
  public tradingPanelPricesLoaded = new BehaviorSubject<boolean>(false);
  public tradingPanelImportTradesListFilterValue = new BehaviorSubject<TradesListFilterFormModel>(
    tradesListFilterFormDefault,
  );
  public tradingPanelImportTradesListSortValue = new BehaviorSubject<Sort>(importTradesListSortDefault);

  // User settings
  public playOpenMarketSound = new BehaviorSubject<boolean>(true);
  public playCloseMarketSound = new BehaviorSubject<boolean>(true);
  public showSmileyStatistics = new BehaviorSubject<boolean>(true);

  public accessLevel = new BehaviorSubject<UserAccessLevels>(UserAccessLevels.Basic);
  public availablePlugins = new BehaviorSubject<string[]>([]);
  public showRockyAlways = new BehaviorSubject<boolean>(DefaultRockyAlwaysVisible);

  // Stock Screener
  public stockScreenerSymbol = new BehaviorSubject<number>(null);
  public stockScreenerChartTab = new BehaviorSubject<number>(0);
  public stockScreenerDetailsTab = new BehaviorSubject<number>(DEFAULT_STOCK_SCREENER_DETAILS_TAB_INDEX);
  public stockScreenerDetailsPanelWidth = new BehaviorSubject<number>(
    STOCK_SCREENER_DETAILS_PANEL_DEFAULT_WIDTH_PERCENT,
  );
  public stockScreenerSymbolDetailsPanelHeight = new BehaviorSubject<number | null>(null);
  public isStockScreenerFiltersHidden = new BehaviorSubject<boolean>(DEFAULT_IS_FILTERS_HIDDEN);
  public stockScreenerFilters = new BehaviorSubject<StockScreenerFilterModel>(DEFAULT_STOCK_SCREENER_FILTERS_STATE);
  public stockScreenerTableSizes = new BehaviorSubject<StockScreenerTableSizes>(DEFAULT_STOCK_SCREENER_TABLE_SIZES);
  public showVolumeIndicatorOnStartForStockScreener = new BehaviorSubject<boolean>(false);
  public lowestHighestCloseLineIndicatorStockScreener = new BehaviorSubject<string>(null);
  public showPowerXStrategyIndicatorStockScreener = new BehaviorSubject<boolean>(true);
  public showExpectedMoveOneOnStockScreener = new BehaviorSubject<number>(1);
  public showExpectedMoveTwoOnStockScreener = new BehaviorSubject<number>(1);
  public valuesInPercentExpectedMoveKeyOnStockScreener = new BehaviorSubject<number>(1);

  // Wtf
  public wtfSymbol = new BehaviorSubject<number>(null);
  public wtfChartTab = new BehaviorSubject<string>(null);
  public wtfShowTradesOnChart = new BehaviorSubject<boolean>(true);
  public wtfAccountSize = new BehaviorSubject<number>(10000);
  public wtfNumberOfPositions = new BehaviorSubject<number>(5);
  public wtfScannerFilters = new BehaviorSubject<IWtfScannerFilter>(DEFAULT_WTF_FILTERS_STATE);
  public isWtfFiltersHidden = new BehaviorSubject<boolean>(DEFAULT_IS_FILTERS_HIDDEN);
  public wtfScannerTab = new BehaviorSubject<number>(DEFAULT_WTF_SCANNER_TAB_INDEX);
  public wtfScannerNewTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public wtfScannerOpenTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public wtfScannerOpenBelowEntryTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public wtfWatchlistTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public wtfDataWindowHeightPercent = new ReplaySubject<number | null>(1);
  public wtfSignalDate = new BehaviorSubject<string | null>(null);
  public wtfShowEntryStopIndicators = new BehaviorSubject<EntryStopIndicatorsOptions>(EntryStopIndicatorsOptions.Both);
  public wtfShowProfitLoss = new BehaviorSubject<boolean>(true);

  public hasEconomicPulseAccess = new BehaviorSubject<boolean>(false);

  public showExpectedMoveOneOnWtf = new BehaviorSubject<number>(1);
  public showExpectedMoveTwoOnWtf = new BehaviorSubject<number>(1);
  public valuesInPercentExpectedMoveKeyOnWtf = new BehaviorSubject<number>(1);

  // Server Data

  public heatmapApiVersion = new BehaviorSubject<number>(1);
  public showStockScreener = new BehaviorSubject<number>(1);
  public showDividendsAnalysis = new BehaviorSubject<number>(0);
  public showImportTrades = new BehaviorSubject<boolean>(false);
  public showTradierImportTrades = new BehaviorSubject<boolean>(false);
  public showVideoHub = new BehaviorSubject<boolean>(false);
  public showRockyDataWindow = new BehaviorSubject<boolean>(false);
  public showRockyEconomicCalendar = new BehaviorSubject<boolean>(false);
  public showHeatmapDetailsDataWindow = new BehaviorSubject<boolean>(false);
  public showDividendsStrategy = new BehaviorSubject<boolean>(false);

  // Dividends
  public dividendsSymbol = new BehaviorSubject<number>(null);
  public dividendsScannerTab = new BehaviorSubject<number>(0);
  public dividendsChartTab = new BehaviorSubject<number>(1);
  public dividendsScannerFilters = new BehaviorSubject<IDividendsScannerFilter>(DEFAULT_DIVIDENDS_FILTERS_STATE);
  public isDividendsFiltersHidden = new BehaviorSubject<boolean>(DEFAULT_IS_DIVIDENDS_FILTERS_HIDDEN);
  public dividendsScannerTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public showPowerXStrategyIndicatorDividends = new BehaviorSubject<PowerXStrategyIndicatorOptions>(
    PowerXStrategyIndicatorOptions.None,
  );

  public lowestHighestCloseLineIndicatorDividends = new BehaviorSubject<LowestHighestCloseLineIndicatorOptions>(
    LowestHighestCloseLineIndicatorOptions.None,
  );
  public lowestCloseLineIndicatorDividends = new BehaviorSubject<LowestCloseLineIndicatorOptions>(
    LowestCloseLineIndicatorOptions.LowestLine,
  );

  public dividendsDataWindowHeightPercent = new BehaviorSubject<number | null>(null);
  public dividendsDataTableHeightPercent = new BehaviorSubject<number | null>(null);

  // Dividends Strategy
  public dividendsStrategyChartTab = new BehaviorSubject<string>(ChartTabs.Chart);
  public dividendsStrategySymbol = new BehaviorSubject<number | null>(null);
  public dividendsStrategyScannerFilters = new BehaviorSubject<DividendsStrategyScannerFilterModel>(
    DEFAULT_DIVIDENDS_STRATEGY_FILTERS_STATE,
  );
  public isDividendsStrategyFiltersHidden = new BehaviorSubject<boolean>(false);
  public dividendsStrategyBuyingPower = new BehaviorSubject<number>(100000);
  public dividendsStrategyNumberOfPositions = new BehaviorSubject<number>(5);
  public dividendsStrategyDataWindowHeightPercent = new BehaviorSubject<number | null>(null);
  public dividendsStrategySignalDate = new BehaviorSubject<string | null>(null);
  public dividendsStrategyShowEntryIndicator = new BehaviorSubject<string>(entryIndicatorsOptions.Show);
  public dividendsStrategyShowProfitLoss = new BehaviorSubject<boolean>(true);
  public dividendsStrategyShowTradesOnChart = new BehaviorSubject<boolean>(true);
  public showPowerXStrategyIndicatorDividendsStrategy = new BehaviorSubject<boolean>(false);
  public lowestHighestCloseLineIndicatorDividendsStrategy = new BehaviorSubject<LowestHighestCloseLineIndicatorOptions>(
    LowestHighestCloseLineIndicatorOptions.Both,
  );
  public lowestCloseLineIndicatorDividendsStrategy = new BehaviorSubject<LowestCloseLineIndicatorOptions>(
    LowestCloseLineIndicatorOptions.LowestLine,
  );

  // Short selling stocks
  public shortSellingStocksSymbol = new BehaviorSubject<number>(null);
  public shortSellingStocksChartInterval = new BehaviorSubject<string | null>(null);
  public shortSellingStocksDataWindowHeightPercent = new BehaviorSubject<number | null>(null);
  public shortSellingStocksChartTab = new BehaviorSubject<number>(1);
  public shortSellingStocksScannerTab = new BehaviorSubject<number>(0);
  public shortSellingStocksScannerNewTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortSellingStocksScannerOpenTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortSellingStocksScannerAboveEntryTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortSellingStocksScannerExitTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortSellingStocksWatchlistTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortSellingStocksMaxUpdatedDate = new BehaviorSubject<moment.Moment | null>(null);
  public shortSellingStocksScannerList = new BehaviorSubject<IShortSellingStocksScannerItem[]>([]);
  public shortSellingStocksScannerItemSelected = new BehaviorSubject<IShortSellingStocksScannerItem | null>(null);
  public shortSellingStocksScannerItemStateUpdated = new BehaviorSubject<number | null>(null);
  public isShortSellingStocksScannerHidden = new BehaviorSubject<boolean>(
    DEFAULT_IS_SHORT_SELLING_STOCKS_FILTERS_HIDDEN,
  );
  public shortSellingStocksScannerFilters = new BehaviorSubject<IShortSellingStocksScannerFilter>(
    DEFAULT_SHORT_SELLING_STOCKS_FILTERS_STATE,
  );
  public shortSellingStocksBuyingPower = new BehaviorSubject<number>(100000);
  public shortSellingStocksNumberOfPositions = new BehaviorSubject<number>(5);
  public showRobIndicatorsShortSellingStocks = new BehaviorSubject<boolean>(true);

  // Shorting Scanner
  public shortingScannerSymbol = new BehaviorSubject<number>(null);
  public shortingScannerChartInterval = new BehaviorSubject<string | null>(null);
  public shortingScannerDataWindowHeightPercent = new BehaviorSubject<number | null>(null);
  public shortingScannerChartTab = new BehaviorSubject<number>(1);
  public shortingScannerScannerTab = new BehaviorSubject<number>(0);
  public shortingScannerScannerNewTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortingScannerScannerOpenTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortingScannerScannerAboveEntryTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortingScannerScannerExitTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortingScannerWatchlistTableSort = new BehaviorSubject<SortState>(DEFAULT_SCANNER_TABLE_SORT);
  public shortingScannerMaxUpdatedDate = new BehaviorSubject<string | null>(null);
  public isShortingScannerFilterHidden = new BehaviorSubject<boolean>(
    DEFAULT_IS_SHORTING_STOCKS_SCANNER_FILTERS_HIDDEN,
  );
  public shortingScannerScannerFilters = new BehaviorSubject<ShortingStocksScannerFilterModel>(
    INITIAL_SHORTING_STOCKS_SCANNER_FILTERS_STATE,
  );
  public shortingScannerBuyingPower = new BehaviorSubject<number>(100000);
  public shortingScannerNumberOfPositions = new BehaviorSubject<number>(5);
  public showRobIndicatorsShortingScanner = new BehaviorSubject<boolean>(true);
  public shortingScannerFiltersWidth = new BehaviorSubject<number>(null);
  public shortingScannerDisplayHalts = new BehaviorSubject<boolean>(false);

  // Observables that are not used as user-settings
  // TODO: move stockScreenerUpdateDate to specific service
  public stockScreenerUpdateDate$ = new BehaviorSubject<string | null>(null);
}
